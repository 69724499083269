import React from "react"
import Layout from "../../components/layout/layout"

import broilletChristophe from "../../img/committee_pic/christophe_broillet.png"
import levionnoisMarcAntoine from "../../img/membres/levionnoisMarcAntoine.png"
import baoluu from "../../img/membres/baoluu.jpg"
import jay from "../../img/membres/jay.png"
import christophe from "../../img/membres/christophe.png"
import melanie from "../../img/membres/melanie.png"
import heloisecoen from "../../img/membres/heloisecoen.jpg"
import florencefrancey from "../../img/membres/florencefrancey.png"

import SmallHero from "../../components/layout/smallHero"
import Avatar from "../../components/Avatar"
import { Helmet } from "react-helmet"

import "../mystyles.scss"

const ComiteeMembers = [
  {
    name: "Christophe Broillet",
    title: "Grand-Sorcier",
    pic: broilletChristophe,
    mail: "grand-sorcier@team-kangourou.ch",
  },
  {
    name: "Marc-Antoine Levionnois",
    title: "Président",
    pic: levionnoisMarcAntoine,
    mail: "president@team-kangourou.ch",
  },
  {
    name: "Christophe Heckel",
    title: "Caissier",
    pic: christophe,
    mail: "caissier@team-kangourou.ch",
  },
  {
    name: "Héloïse Coen",
    title: "Secretaire et Resp. patisserie",
    pic: heloisecoen,
    mail: "secretaire@team-kangourou.ch ",
    mail2: "patisserie@team-kangourou.ch",
  },
  {
    name: "Florence Francey",
    title: "Resp. technique",
    pic: florencefrancey,
    mail: "technique@team-kangourou.ch",
  },
  // {
  //   name: "Jeremy Jordan",
  //   title: "Resp. communication",
  //   pic: jay,
  //   mail: "communication@team-kangourou.ch",
  // },
  {
    name: "Mélanie Pereira Carvalho",
    title: "Resp. événement & communication",
    pic: melanie,
    mail: "info@team-kangourou.ch",
    mail2: "communication@team-kangourou.ch",
  },
  {
    name: "Quoc Bao Luu",
    title: "Resp. IT",
    pic: baoluu,
    // mail: "",
  },
]

export default function Comite() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Comité</title>
      </Helmet>
      <SmallHero title="Le comité" color="is-warning" />
      <section className="section">
        <div className="container">
          <h1 className="title has-text-centered"> Recontrez notre comité</h1>
          <p className="has-text-centered">
            Voici les fantastiques membres de notre comité !{" "}
          </p>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline">
            {ComiteeMembers.map(({ name, title, pic, mail, mail2 }) => (
              <Avatar
                name={name}
                title={title}
                pic={pic}
                mail={mail}
                mail2={mail2}
              />
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}
